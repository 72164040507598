import React from 'react'
export const ErrorPage = () => {
  return(
    <div className="container-fluid mt-4">
      <div className="container-lg text-center pt-5">
        <span className="text-danger"> 
          <h1> <span className="fas fa-exclamation-triangle"></span><br/>
            A system error has occured and we are unable to process your request.
          </h1><br/>
        </span><br/>
        Please contact Customer Support: <br/><span className="fas fa-phone"></span>1-800-123-4567 <br/>
        <a href={() => false}><span className="fas fa-envelope"></span> InsMarketInsightsSupport@lexisnexisrisk.com</a>
      </div>
    </div>
  )
}