import  { Component } from 'react'
import VizDataService from '../../service/VizDataService'
    

 class ErrorBoundry extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            hasErrror:false
        }
    }
    
    static getDerivedStateFromError(error) {
        return {
            hasErrror :true
        }
    }

    componentDidCatch(error, info) {
        //console.log(info,'from ErrorBoundry')
        let  data = { 
            'msg': error.toString(), 
            'trace': [
                info.componentStack
            ],
            'component': this.props.children.props.component ? this.props.children.props.component.WrappedComponent.name : ''
         }
        VizDataService.logError(data);
    }

    render() {
       if(this.state.hasErrror) {
           return '<h1>Something went Wrong</h1>'
       }
       return this.props.children
    }
}

export default ErrorBoundry
