import React, {  lazy, Suspense,  useEffect, } from "react";
import {
  FooterNav
} from "@lnts/lnts-react";
import { Route, BrowserRouter, useHistory, useLocation, Switch } from "react-router-dom";
import { ErrorPage } from "./containers/ErrorPage/ErrorPage";
import { SessionNotFound } from "./containers/SessionNotFound/SessionNotFound"
import ErrorBoundry from "./components/errorBoundry/ErrorBoundry";
import VizDataService from "./service/VizDataService";

import useUserDropdownVisible from "./hooks/useUserDropdownVisible.js";

import Modal from "./components/modal/modal.js";
import "unorm";
import queryString from "query-string";
// import jwt from "jwt-decode";
//import { useJwt } from "react-jwt";
const Home = lazy(() => import("./containers/home/Home"));
/* istanbul ignore next */
/*as we are not redirect to report page this line is not covered in unit test */
const ReportChart = lazy(() => import("./containers/ReportChart/ReportChart"));
const ProductPage = lazy(() => import("./containers/productPage/ProductPage"));
const UpdateAnnouncement = lazy(() =>
  import("./containers/updateAnnouncement/UpdateAnnouncement")
);

const InsureQuoteHome = lazy(() =>
  import("./containers/insureQuoteHome/InsureQuoteHome")
);

const MarketIntelligence = lazy(() =>
  import("./containers/marketIntelligence/MarketIntelligence")
);

if (window._env_.REACT_APP_SHOW_CONSOLE !== true){
  console.log = () => {};
}
   



const App = (props) => {
  console.log('app props',props);
  const history = useHistory({forceRefresh:true});
  const location = useLocation();
  const useQuery= () => {
    return new URLSearchParams(location.search);
  }
// const isLogout = (props) => {
//   console.log('islogoutprops',props);
//   const { location } = props;
//   if (location.pathname.indexOf('/logout')) {
//     console.log("location=", location.pathname);
//     let reason = location.pathname.split('/').at(-1);
//     logout((reason === 'logout') ? null : reason);
//   } else {
//     localStorage.removeItem("logoutTriggered");
//   }
//   return true;
// }
  let query = useQuery();
  // const useQuery= () => {
  //   return new URLSearchParams(useLocation().search);
  // }
  // let query = useQuery();
  // console.log("Get data==>",query.get('skey'));
  let search = window.location.search;
  const skeyfromuri = queryString.parse(search).skey;
  //console.log('skey',skey);
  //has to call in useeffect
  //isLogout(props);
  const [siteConfig, setsiteConfig] = React.useState([]);
  const [skey, setskey] = React.useState(skeyfromuri);
  const [showmodal, setshowmodal] = React.useState(false);
  const [marketIntelligneceUser, setmarketIntelligneceUser] = React.useState(false);
  //const [firstLanding, setfirstLanding] = React.useState(false);
  const [logoutTriggered, setlogoutTriggered] = React.useState(false);
  const { userDropdownMenuLink, isUserDropdownVisible, setIsUserDropdownVisible } = useUserDropdownVisible(false);
  const [insureQuoteUser, setInsureQuoteUser] =  React.useState(false);
  //  const [supportMailId, setSupportMailId] =  React.useState("mailto:InsMarketInsightsSupport@lexisnexisrisk.com");
  
  
  
  

  const logout = (reason) => {
    setlogoutTriggered(true);
    VizDataService.logoutCall(reason,(res) => {
      if (res.hasOwnProperty("error")) {
        console.log("error occured in logout call");
      } else {
        //Token.removeToken();
        localStorage.removeItem("productRedirect");
        localStorage.removeItem("productPageVisited");
        // localStorage.removeItem("IQDPRedirect");
        if (res.url) {
        	window.location.href = res.url;
          //window.location.replace(res.url);
        }
      }
    });
  }

  const navigateToWorkspace = (url) => {
    localStorage.removeItem("productRedirect");
    localStorage.removeItem("productPageVisited");
    window.location.href = url;
  }
  
 const insuranceQuoteHelp = () => {
    setshowmodal(true);
  }

  const sanitizeURL = () =>  {
    console.log('1111');
    console.log("Get data==>",query.get('skey'));

    // Remove skey from url
    if (query.get("skey")) {
      setskey(query.get("skey"));
      console.log('22222');
      query.delete("skey");

      history.replace({
        search: query.toString(),
      });
    }
  }

  // const isUserAuthorized = () => {
    // let accessToken = Token.getAccessToken();
    // let refreshToken = Token.getRefreshToken();
    // if (accessToken!= '' &&  refreshToken != '') {
      
    //   setauthorized(true);
    // }
    // setauthorized(false);
  // }


  //check if common items are present in both arrays
  const haveCommonGroups = (group1, group2) => {
    let set1 = new Set(group1);
    let commonItems = group2.filter(item => set1.has(item));
    return commonItems.length > 0;
  }

  const skipUseEffectCall = () =>  {
    if(location.pathname.includes('/logout/sessionnotfound')) {
      console.log('skipUseEffectCall');
      return true;
    }
  }

  //headerlinkupdates by event
  // useEffect(() => {
  //   const handleHeaderLinkUpdate = (event) => {
  //     // Access data from event.detail.data
  //     setSupportMailId(event.detail.supportMailId);
  //   };

  //   window.addEventListener('header-link-update', handleHeaderLinkUpdate);

  //   return () => {
  //     window.removeEventListener('header-link-update', handleHeaderLinkUpdate);
  //   };
  // }, []);
 
  useEffect(()=>{
    sanitizeURL();
  })

  useEffect(() => {

    if(!skipUseEffectCall()) {

      console.log('props for useEffect',props);
      console.log('aftersanitize');
      let param;
      if (skey) {
        param = {
          skey: skey,
        };

        VizDataService.getAuthorizationCall(param, (res) => {
          if (res.hasOwnProperty("error")) {
            console.log("errror occured in getauthorization");
            // Some problem with Session, logout redirect
            //this.logout("errror occured in getauthorization");
          } else {
            console.log("response=", res);
            // Recieved access token
            if (res.message === "Token Granted") {
              //const token = res.jwt;
              // Set token in localStorage
              // localStorage.setItem("token", token);
              // Token.setAccessToken(token);
              // Token.setRefreshToken(res.refreshtoken);
              //isUserAuthorized();
              setskey('');
              //setauthorized();
              VizDataService.getHeaderConfigCall((res) => {
                if (res.hasOwnProperty("error")) {
                  console.log("errror occured in headerconfigcall");
                } else {
                  console.log("headerconfigcall response=", res);
                  console.log("currentLogoutUrl", res.logout)
                  localStorage.setItem("currentLogoutUrl", res.logout);
                  localStorage.setItem("logoutUser", res.userId);

                  setsiteConfig(res);
                  if (res.expired) {
                    setshowmodal(true);
                  }
                  // document.dispatchEvent(new CustomEvent("headerconfigloaded"));
                  res.userPreferences.forEach(element => {
                    if(element.pref_key === "selectedProduct") {
                      if(localStorage.getItem("productPageVisited") !== "true") {
                        localStorage.setItem("productRedirect", element.pref_value);
                      }
                    }
                  });
                  
                  if (res.group !== undefined && haveCommonGroups(res.group, window._env_.REACT_APP_INTELLIGENCE_GROUP) > 0) {
                  console.log('reachec to  market intelligence',)
                  setmarketIntelligneceUser(true);
                  }

                  if(res.group !== undefined && haveCommonGroups(res.group, window._env_.REACT_APP_INSUREQUOTE_GROUP) > 0) {
                    console.log('reached to insure quote',)
                    setInsureQuoteUser(true);
                    Object.keys(localStorage).forEach(key => {
                      if (key.includes('_reportname') || key === 'closeTab' || key.includes('_manualSaved') || key.includes('_manualSavedReRender') || key.includes('_tokenNotProvided')) {
                        localStorage.removeItem(key);
                      }
                    });
                    // history.push('/insurequote');
                    // if(localStorage.getItem("IQDPRedirect") !== 'true') {
                    //   localStorage.setItem("IQDPRedirect", 'true');
                    // }
                  }
          
                }
              });
              
            } else {
              console.log("token not granted");
              // JWT is not returned, which means something went wrong with AuthZ
              // We need to logout user
              //Token.removeToken();
              //this.logout("token not granted");
            }
          }
        });
      } else if (!logoutTriggered) {
        // isUserAuthorized();
        //setauthorized(isUserAuthorized());
        VizDataService.getHeaderConfigCall((res) => {
          if (res.hasOwnProperty("error")) {
            console.log("errror occured in headerconfigcall");
            // cannot proceed with errors
            //Token.removeToken();
            //this.logout("errror occured in headerconfigcall");
          } else {
            console.log("headerconfigcall response=", res);
            console.log('res.expiredssss',res.expired)
            console.log("currentLogoutUrl", res.logout)
            localStorage.setItem("currentLogoutUrl", res.logout);
            setsiteConfig(res);
            if (res.expired) {
              setshowmodal(true);
            }
            // document.dispatchEvent(new CustomEvent("headerconfigloaded"));

            res.userPreferences.forEach(element => {
              if(element.pref_key === "selectedProduct") {
                if(localStorage.getItem("productPageVisited") !== "true") {
                  localStorage.setItem("productRedirect", element.pref_value);
                }
              }
            });

            if(res.group !== undefined && haveCommonGroups(res.group, window._env_.REACT_APP_INTELLIGENCE_GROUP) > 0) {
              console.log('reachec to marketintelligence',)
              setmarketIntelligneceUser(true);
            } 

            if(res.group !== undefined && haveCommonGroups(res.group, window._env_.REACT_APP_INSUREQUOTE_GROUP) > 0) {
              console.log('reached to insure quote',)
              setInsureQuoteUser(true);
              Object.keys(localStorage).forEach(key => {
                if (key.includes('_reportname') || key === 'closeTab' || key.includes('_manualSaved') || key.includes('_manualSavedReRender') || key.includes('_tokenNotProvided')) {
                  localStorage.removeItem(key);
                }
              });
              // history.push('/insurequote');
              // if(localStorage.getItem("IQDPRedirect") !== "true") {
              //   localStorage.setItem("IQDPRedirect", "true");
              // }
            }
            document.title = res.title;
          }
        });
      }
    }
    //eslint-disable-next-line
  },[skey]);


  const hideModal = () => {
    setshowmodal(false);
  };

  var {
    userName,
    userMgmt,
    roles,
    firstName,
    lastName,
    email,
    expired,
    expirationNotice,
    chatNotice,
    chatLink,
    group,
    title,
    userProducts,
    otherProducts,
    workspace,
    account,
    help,
    isDisplayUserManagementLink,
    isDisplaySecuritySettingsLink
  } = siteConfig;


  return (
  <>
    <link rel="stylesheet" href="/vizportal/marketIntelligence/style.css" />
    <link rel="stylesheet" href="/vizportal/marketIntelligence/color.css" />
    <link rel="stylesheet" href="/vizportal/marketIntelligence/all.css" />
    <link rel="stylesheet" href="/vizportal/marketIntelligence/fontawesome.css" />
    <link rel="stylesheet" href="/vizportal/marketIntelligence/table.css" />
          
    <div className="lnts-header fixed-top bg-white flex-md-nowrap px-3">
      <div className="row shadow border-bottom pt-3 pb-2">

        <div className="col-lg-8 col-md-6 col-sm-6">
          <h1><span className="product-name pt-0">
          <a href="//lexisnexisrisk.com" class="ln-logo" target="_blank" rel="noreferrer">
            <img className="product-logo" src="/vizportal/marketIntelligence/lnrs_logo.svg" alt="Lexis Nexis Risk Solutions Logo.  Click to go to the Homepage." title="Lexis Nexis Risk Solutions Logo.  Click to go to the Homepage." />
          </a>
          {
            (insureQuoteUser) ? (
              <a href={window._env_.PUBLIC_URL + "/insurequote"} class="h1 vertical-name">{title}</a>
            ) : (
              <a href={window._env_.PUBLIC_URL} class="h1 vertical-name">{title}</a>
            )
          }
          </span></h1>
        </div>
      
        <div className="col-lg-4 col-md-6 col-sm-6">
          <ul className="nav float-right">

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-darkblue" href={() => false} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref={userDropdownMenuLink} onClick={() => setIsUserDropdownVisible(!isUserDropdownVisible)}>
                <span className="fas fa-user fa-w-14 mr-1"></span> {userName}
                {
                  (isDisplaySecuritySettingsLink || isDisplayUserManagementLink) && 
                  (<span className="fas fa-sort-down fa-w-14 fa-fw" style={{ verticalAlign: "top" }}></span>)
                }
              </a>
              {(isUserDropdownVisible && (isDisplaySecuritySettingsLink || isDisplayUserManagementLink)) &&
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink" style={{ "display": "block" }}>
                  { isDisplaySecuritySettingsLink && (<a className="dropdown-item nav-link text-darkblue border-bottom" href={account}><i className="fas fa-lock fa-w-14 mr-1"></i> Security Settings</a>)}
                  {isDisplayUserManagementLink && (<a className="dropdown-item nav-link text-darkblue" href={userMgmt}><i className="fas fa-users-cog fa-w-14 mr-1"></i><span className="sr-only">(current)</span> Manage Users</a>)}
                </div>
              }
            </li>


            <li className="nav-item  ">
              <a className="nav-link text-darkblue px-2" href={() => false} onClick={() => navigateToWorkspace(workspace)} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Workspace">Workspace</a>
            </li>
            
            {/* {(marketIntelligneceUser || insureQuoteUser) && (
              <li className="nav-item  ">
                <a className="nav-link text-darkblue" href={supportMailId} data-toggle="tooltip" data-placement="bottom" title="" data-for="tooltip" data-tip="Live Chat &amp; Customer Support" target="_blank" rel="noreferrer"><i className="fas fa-envelope" aria-hidden="true"></i><span className="sr-only">(current)</span></a>
              </li>
            )} */}

            {/* {(marketIntelligneceUser || insureQuoteUser) && (<li className="nav-item  ">
              <a className="nav-link text-darkblue" href="mailto:InsMarketInsightsSupport@lexisnexisrisk.com" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Live Chat &amp; Customer Support" data-original-title="Live Chat &amp; Customer Support" target="_blank" rel="noreferrer" data-for="tooltip" data-tip="Live Chat &amp; Customer Support"><i className="fas fa-comments" aria-hidden="true"></i><span className="sr-only">(current)1234</span></a>
            </li>)} */}
            
            {/* {(marketIntelligneceUser || insureQuoteUser) && (<li className="nav-item">
              <a className="nav-link text-darkblue" href="/vizportal/help/reporting/" target="_blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="FAQs" data-for="tooltip" data-tip="FAQs"><i className="fas fa-question-circle"></i></a>
            </li>)} */}
            
            {(insureQuoteUser) && (<li className="nav-item">
              <a className="nav-link text-darkblue px-2" href={() => false} onClick={() => insuranceQuoteHelp()} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="FAQs" data-for="tooltip" data-tip="FAQs"><i className="fas fa-question-circle"></i></a>
            </li>)}

            <li className="nav-item">
              <a className="nav-link text-darkblue px-2" href={() => false} onClick={() => logout('manual logut click')}><i className="fas fa-sign-out-alt"></i></a>
            </li>

          </ul>
        </div>

      </div>
  
    </div>
  
    <ErrorBoundry>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter basename={window._env_.PUBLIC_URL}>
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <>
                  {(marketIntelligneceUser || insureQuoteUser) && (
                    <Home
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      roles={roles}
                      userName={userName}
                      expired={expired}
                      chatNotice={chatNotice}
                      chatLink={chatLink}
                      expirationNotice={expirationNotice}
                      group={group}
                      productInfo={userProducts}
                      otherProductsInfo={otherProducts}
                      bannerContent={window._env_.REACT_APP_BANNER_CONTENT ?? ""}
                      bannerDisplay={window._env_.REACT_APP_BANNER_DISPLAY ?? false}
                    />
                  )}
                  {showmodal && (
                    <Modal>
                      <h3 class="lnts-heading text-danger h2">
                        <b>{expirationNotice}</b>
                      </h3>
                    </Modal>
                  )}
                </>
              )}
            />
            <Route
              path="/:productid/reportchart/:id" 
              render={(props) => (
                <>
                  {(marketIntelligneceUser || insureQuoteUser) && (
                    <ReportChart 
                      productInfo={userProducts}
                      hideUserDropdownVisible={() => {setIsUserDropdownVisible(false)}}
                    />
                  )}
                </>
              )}
            />
            <Route
              path="/productpage/:productid"
              render={(props) => (
                <>
                  {(marketIntelligneceUser || insureQuoteUser) && (
                    <ProductPage
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      roles={roles}
                      userName={userName}
                      expired={expired}
                      chatNotice={chatNotice}
                      chatLink={chatLink}
                      expirationNotice={expirationNotice}
                      group={group}
                      productInfo={userProducts}
                    />
                  )}
                </>
              )}
            />
            <Route
              path="/updateannouncement/:productid"
              exact
              render={(props) => (
                <>
                  {roles && (
                    <UpdateAnnouncement roles={roles} {...props} />
                  )}
                </>
              )}
            />
            <Route
              path="/errorpage"
              exact
              component={ErrorPage}
            />
            <Route
              path="/insurequote"
              exact
              render={(props) => (
                <>
                  {insureQuoteUser && (
                    <InsureQuoteHome help={help} showModal={showmodal} hideModalHandler={hideModal} />
                  )}
                </>
              )}
            />
            <Route
              path="/marketintelligence"
              exact
              render={(props) => (
                <>
                  <MarketIntelligence />
                </>
              )}
            />
            <Route
              path="/logout/sessionnotfound"
              exact
              component={SessionNotFound}
            />
            <Route
              component={ErrorPage}
            />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundry>

    <ErrorBoundry>
      <FooterNav
        showRelxLogo
        lexisNexisAboutUrl="https://risk.lexisnexis.com/about-us"
        lexisNexisPrivacyUrl="https://risk.lexisnexis.com/privacy-policy"
        lexisNexisTermsUrl="https://risk.lexisnexis.com/terms"
      />
    </ErrorBoundry>
  </>
  );
  
}
export default App;
