import axios from "axios";
import VizDataService from "./VizDataService";

const instance = axios.create({
	baseURL: window._env_.REACT_APP_API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

// instance.interceptors.request.use(
// 	(config) => {
// 		const token = TokenService.getAccessToken();
// 		if (token) {
// 			config.headers["Authorization"] = `Bearer ${token}`; // for Node.js Express back-end
// 		}
// 		return config;
// 	},
// 	(error) => {
// 		return Promise.reject(error);
// 	}
// );

instance.interceptors.response.use(
	(res) => {
		const tokens = res.data;
		//let logoutTriggered = localStorage.getItem("logoutTriggered");
		if (tokens.message === 'Token Granted' && tokens.isTokenRegenerated) {
			const originalConfig = res.config;
			console.log('originalConfig',originalConfig);
			if (originalConfig.url !== "/director" && originalConfig.url !== "/newtoken" ) {
				if (!originalConfig._retry) {
					originalConfig._retry = true;
					return instance(originalConfig);
				}
			}
		
		} else {
			return res;
		}
		
	},
	async (err) => {
		const errOriginalConfig = err.config;
		if(err.response.status === 401) {
			VizDataService.logError({
				'msg': 'Something went wrong.' + errOriginalConfig.url,
				'trace': err,
				'component':'apiService',
			});
			triggerLogout();
		}
		
		return Promise.reject(err);
	}
);

const triggerLogout = () => {
	localStorage.setItem("logoutTriggered", true);
	VizDataService.logoutCall('sessionnotfound',(res) => {
		if (res.hasOwnProperty("error")) {
		  console.log("error occured in logout call");
		} 
	  });
	window.location.href = window._env_.PUBLIC_URL + '/logout/sessionnotfound';
};

export default instance;
