import { useState, useEffect, useRef } from "react";

export default function useUserDropdownVisible() {
  const [isUserDropdownVisible, setIsUserDropdownVisible] = useState(false);
  const userDropdownMenuLink = useRef(null);

  const handleClickOutside = (event) => {
    if (userDropdownMenuLink.current && !userDropdownMenuLink.current.contains(event.target)) {
      setIsUserDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, !isUserDropdownVisible);

    return () => {
      document.removeEventListener(
        "click",
        handleClickOutside,
        !isUserDropdownVisible
      );
    };
  });

  return { userDropdownMenuLink, isUserDropdownVisible, setIsUserDropdownVisible };
}