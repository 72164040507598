import React, { useEffect } from 'react';
import VizDataService from "../../service/VizDataService";

export const SessionNotFound = () => {

  const logout = (reason) => {
    VizDataService.logoutCall(reason,(res) => {
      if (res.hasOwnProperty("error")) {
        console.log("error occured in logout call");
      } else {
        if (res.url) {
        	window.location.href = res.url;
         }
      }
    });
  }

  useEffect(()=>{
    logout('Session Not Found');
  });

  return(
    <div className="container-fluid mt-4">
      <div className="container-lg text-center pt-5">
        <span className="text-danger"> 
          <h1><span className="fas fa-exclamation-triangle"></span><br/>
           Please wait ...
          </h1><br/>
          <h3>
          You are being redirected to the login page. Please wait... 
          </h3>
        </span><br/>
        Customer Support: <br/><span className="fas fa-phone"></span>1-800-123-4567 <br/>
        <a href={() => false}><span className="fas fa-envelope"></span> InsMarketInsightsSupport@lexisnexisrisk.com</a>
      </div>
    </div>
  )
}